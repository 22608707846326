import { gettext } from './text';

const translations = {
    weight: gettext('Weight'),
    inner_color: gettext('Inner color'),
    config: gettext('Diameter'),
    length_produced: gettext('Length produced'),
    length_ordered: gettext('Length ordered'),
    inner_thicknesses: gettext('Profile Gap'),
    outer_thicknesses: gettext('Profile Thickness'),
    rib_caps: gettext('Rib Gaps'),
    socket_diameter: gettext('Socket Diameter'),
    socket_thicknesses: gettext('Socket Thickness'),
    spigot_diameter: gettext('Spigot Diameter'),
    type: gettext('Profile type'),
    TYPE: gettext('Profile type'),
    core_tube_config: gettext('Profile core diameter'),
    moment_of_inertia: gettext('Moment of inertia'),
    end_front: gettext('Pipe front end'),
    end_back: gettext('Pipe back end'),
    ring: gettext('Ring'),
    operator: gettext('Operator'),
    robot_operator: gettext('Robot operator'),
    directo_article: gettext('Directo Article'),
    directo_entry_date: gettext('Directo entry date'),
    directo_reentry_date: gettext('Directo reentry date'),
    status: gettext('Status'),
    production_status: gettext('Status'),
    'Production Start': gettext('Production Start'),
    is_special: gettext('Special'),
    is_DET: gettext('DET'),
    is_polymark: gettext('Polymark'),
    RC: gettext('RC'),
    grinder: gettext('Grinder'),
    finisher: gettext('Finisher'),
    finisher_date: gettext('Finisher date'),
    welding_installer: gettext('Welding installer'),
    measurer: gettext('Measurer'),
    measurer_date: gettext('Measurer date'),
    client_name: gettext('Client name'),
    client_name_short: gettext('Customer'),
    order_number: gettext('Order number'),
    is_maintenance: gettext('Maintenance'),
    maintenance_pipe: gettext('Maintenance Pipe'),

    // orders
    client: gettext('Client'),
    number: gettext('Number'),
    date: gettext('Date'),
    worker: gettext('Worker'),
    last_modified: gettext('Timestamp'),
    pipe_faulty_status: gettext('This pipe has faulty status'),
    is_robot_pipe: gettext("Is robot's pipe"),

    exists: gettext('exists'),
    missing: gettext('missing'),
    without: gettext('without'),
    true: gettext('true'),
    false: gettext('false'),
    equals: gettext('equals'),
    not_equals: gettext('not equals'),
    less_than: gettext('less than'),
    less_than_equal: gettext('less than equal'),
    greater_than: gettext('greater than'),
    greater_than_equal: gettext('greater than equal'),
    in_range: gettext('in range'),
    less_than_allowed: gettext('less than allowed'),
    greater_than_allowed: gettext('greater than allowed'),
    inside_allowed: gettext('inside allowed'),
    outside_allowed: gettext('outside allowed'),
    all_pp_rc: gettext('all PPRC'),
    all_pe_rc: gettext('all PERC'),
    contains: gettext('contains'),
    not_contains: gettext('not contains'),

    // operations
    diameter: gettext('Diameter'),
    sn_class: gettext('SN'),
    quantity: gettext('Quantity'),
    tools: gettext('Tools'),
    plan_value: gettext('RC %'),
    comment: gettext('Comment'),
    order: gettext('Order'),
    start_time: gettext('Start time'),
    end_time: gettext('End time'),
    actual_start_time: gettext('Actual start'),
    actual_end_time: gettext('Actual end'),
    profile: gettext('Profile'),
    length: gettext('Length'),
    ends: gettext('Ends'),
    pause_reason: gettext('Pause reason'),
    production_time: gettext('Production time'),

    /**
     * Seems that gettext is having issues parsing some JS files, some of these translations
     * Some of the following translatiosn rae not directly being referanced, but they do have a matching gettext in krah
     * They are here as well so make-messages works.
     */

    body_cutting: gettext('Body cutting'),
    body_expansion: gettext('Body expansion'),

    loading: gettext('Loading...'),
    pipe_diameter_mm: gettext('Pipe diameter (mm)'),
    expansion_count: gettext('Expansion count'),
    expansion_operator: gettext('Expansion operator'),
    needs_cutting: gettext('Needs cutting'),
    cutting_operator: gettext('Cutting operator'),

    order_import_date: gettext('Order import date'),
    edit_pipe: gettext('Edit pipe'),
    edit_selected_part: gettext('Edit selected part'),
    extra_info_for_operator: gettext('Extra info for operator'),
    forecast_amounts: gettext('Forecast amounts'),
    forecast_for_produced_products: gettext('Forecast for produced products'),
    friday: gettext('Friday'),
    input_in_focus: gettext('Input in focus'),
    insert_tools: gettext('Insert tools'),
    last_change: gettext('Last change'),
    lines: gettext('Lines:'),
    loading_more_orders: gettext('Loading more orders..'),
    mark_all_done: gettext('Mark all done'),
    max_pipes_per_day: gettext('Max pipes per day'),
    measurement: gettext('Measurement:'),
    Minutes: gettext('Minutes'),
    minutes: gettext('minutes'),
    Missing: gettext('Missing'),
    monday: gettext('Monday'),
    natural_percentage: gettext('Natural percentage: %s%'),
    next_shift: gettext('Next shift'),
    no_action_chosen: gettext('No action chosen.'),
    no_comment_added: gettext('No comment added'),
    now: gettext('Now'),
    overtime: gettext('Overtime'),
    perc_pprc: gettext('PERC/PPRC'),
    paint_percentage: gettext('Paint percentage: %s%'),
    pair_working: gettext('Pair-working'),
    period_end: gettext('Period end'),
    period_start: gettext('Period start'),
    pieces: gettext('Pieces'),
    print_label: gettext('Print label'),
    products_with_core_tube: gettext('Products with core tube'),
    products_without_core_tube: gettext('Products without core tube'),
    profile_time: gettext('Profile time'),
    profile_number: gettext('Profile number'),
    remove_selected_parts_from_the_calendar: gettext(
        'Remove selected parts from the calendar',
    ),
    revoke_selected_worker_from_workplan: gettext(
        'Revoke selected worker from workplan',
    ),
    saturday: gettext('Saturday'),
    save_template: gettext('Save template'),
    scanner_ready: gettext('Scanner ready'),
    scrap_rate: gettext('Scrap rate: %s%'),
    scrolling: gettext('Scrolling'),
    sunday: gettext('Sunday'),
    that_action_has_already_been_assigned_to_the_worker: gettext(
        'That action has already been assigned to the worker.',
    ),
    this_view_gives_the_overview_of_pipes_produced: gettext(
        'This view gives the overview of pipes produced.',
    ),
    thursday: gettext('Thursday'),
    total_amounts: gettext('Total amounts'),
    tuesday: gettext('Tuesday'),
    wednesday: gettext('Wednesday'),
    zoom_out: gettext('Zoom out'),
    changes: gettext('changes'),
    hours: gettext('hours'),
    mark_as_completed: gettext('Mark as completed'),
    in_directo: gettext('In Directo'),

    material_forcast: gettext(
        'This view gives the forecast of material costs for products that have been planned',
    ),

    PIPE: gettext('PIPE'),
    PART: gettext('PART'),

    // EditPipeForm.js
    Avg: gettext('Avg'),

    'Directo Article': gettext('Directo Article'),

    'Directo Date': gettext('Directo Date'),

    'Inner Thickness Required': gettext('Inner Thickness Required'),

    'Inner Thickness Tolerance': gettext('Inner Thickness Tolerance'),

    'Length required': gettext('Length required'),

    'Length tolerance': gettext('Length tolerance'),

    Max: gettext('Max'),

    'Max Outer Diameter': gettext('Max Outer Diameter'),

    'Measuring Time': gettext('Measuring Time'),

    Min: gettext('Min'),

    Notes: gettext('Notes'),

    'Operation comment': gettext('Operation comment'),

    'Outer Thickness Required': gettext('Outer Thickness Required'),

    'Outer Thickness Tolerance': gettext('Outer Thickness Tolerance'),

    'PE RC%': gettext('PE RC%'),

    'PP RC%': gettext('PP RC%'),

    'Pipe ID': gettext('Pipe ID'),
    'Pipe Product ID': gettext('Pipe Product ID'),

    'Pipe ends': gettext('Pipe ends'),

    'Rib Cap Required': gettext('Rib Cap Required'),

    'Rib Cap Tolerance': gettext('Rib Cap Tolerance'),

    'Roundness Allowed': gettext('Roundness Allowed'),

    'Roundness End': gettext('Roundness End'),

    'Roundness Max Diameter': gettext('Roundness Max Diameter'),

    'Roundness Min Diameter': gettext('Roundness Min Diameter'),

    'SN class': gettext('SN class'),

    'Socket Diameter Required': gettext('Socket Diameter Required'),

    'Socket Diameter Tolerance': gettext('Socket Diameter Tolerance'),

    'Socket Thickness Required': gettext('Socket Thickness Required'),

    'Socket Thickness Tolerance': gettext('Socket Thickness Tolerance'),

    'Spigot Diameter Required': gettext('Spigot Diameter Required'),

    'Spigot Diameter Tolerance': gettext('Spigot Diameter Tolerance'),

    Standard: gettext('Standard'),

    Tags: gettext('Tags'),

    'Weight suggested': gettext('Weight suggested'),
    Overview: gettext('Overview'),
    General: gettext('General'),
    Curvature: gettext('Curvature'),
    Socket: gettext('Socket'),
    Spigot: gettext('Spigot'),
    Technical: gettext('Technical'),
    Ribs: gettext('Ribs'),
    Save: gettext('Save'),
    Cancel: gettext('Cancel'),
    Refresh: gettext('Refresh'),
    'Changes History': gettext('Changes History'),

    'Changes saved successfully': gettext('Changes saved successfully'),

    'Measurement results': gettext('Measurement results'),

    'Overview & Pipe Result': gettext('Overview & Pipe Result'),

    'Pipe result': gettext('Pipe result'),

    'Saving..': gettext('Saving..'),

    'Spigot & Socket': gettext('Spigot & Socket'),

    machine_operating_mode: gettext('Machine operating mode'),
    machine_operating_mode_end: gettext('Machine operating mode end'),

    // translators: QRS Filter label for pipe tags
    tags: gettext('Pipe Tags'),

    // QRS filter, it splits off the end of the query param, so translation text not so nice
    isnull: gettext('Is missing Production data'),

    // translators: Multi-select operator
    any_of: gettext('Contains any of'),
    // translators: Multi-select operator
    none_of: gettext('Does not contain'),
    // translators: Multi-select operator
    all_of: gettext('Contains selected'),

    'Gantt chart': gettext('Gantt chart'),

    mo_reference: gettext('MO Number'),
    order_type: gettext('Order Type'),
    commitment_date: gettext('Commitment Date'),

    odoo_confirm_type: gettext('Odoo Confirmed Type'),
    odoo_confirmed_as: gettext('Odoo Confirmed As Status'),
    odoo_confirmed_at: gettext('Odoo Confirmed At'),
    odoo_confirmed_at_help_text: gettext(
        'Manual confirm is enabled because automatic confirmation with Odoo failed. Only set this if you have confirmed that it has been confirmed in Odoo.',
    ),
    odoo_confirmed_measured_length_mm: gettext(
        'Odoo Confirmed Measured Length (mm)',
    ),
    odoo_server_error_messages: gettext('Odoo server error messages'),
    odoo_ready_to_confirm: gettext('Ready To Confirm in Odoo'),
    odoo_article: gettext('Odoo Article'),
    odoo_final_article: gettext('Odoo Final Article'),
    odoo_has_mismatched_confirmed_status: gettext(
        'Odoo has mismatched confirmed status',
    ),
    odoo_is_failing_to_confirm: gettext('Is failing to confirm in Odoo'),
    is_failing: gettext('Is failing to confirm'),
    not_failing_anymore: gettext('Has confirmed but with Odoo errors'),
    never_failed: gettext('Has never failed'),
    ordered_article: gettext('Ordered Article'),
    manufacturing_order: gettext('Manufacturing Order'),
    actual: gettext('Actual'),
    planned: gettext('Planned'),
    override: gettext('Override'),
    scrap: gettext('Scrap'),
    print_message: gettext('Sent Print Message'),
    print_message_default: gettext('Print Message Default'),
    print_message_configured: gettext('Print Message Configured'),
};

export default translations;

export const getTranslation = (key) => {
    const translation = translations[key];
    if (translation) {
        return translation;
    } else {
        const getTextValue = gettext(key);
        console.error(
            `No translation for key: ${key}, using gettext value: ${getTextValue}`,
        );
        return getTextValue;
    }
};
